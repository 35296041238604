<template>
  <v-dialog v-model="dialog" :max-width="$vuetify.breakpoint.xs ? 374 : 750">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
    </template>
    <v-card class="py-2">
      <v-img class="mx-2" height="130px" src="/driver_background.jpeg">
        <v-row no-gutters class="justify-center">
          <v-avatar class="mt-3" size="48px">
            <v-img v-if="item.image" :src="item.image" />
            <v-img v-else src="/user_avatar.png" />
          </v-avatar>
        </v-row>
        <v-row no-gutters class="justify-center mt-2">
          <span class="white--text">{{ item.name }}</span>
        </v-row>
        <v-row no-gutters class="justify-center mt-2">
          <span class="text-body-2 white--text">{{ item.phone | normalizePhone }}</span>
        </v-row>
      </v-img>
      <v-row no-gutters dense class="mt-4 mx-2 hidden-xs-only">
        <v-col cols="3" class="row no-gutters justify-center">
          <span class="text-caption">Номер</span>
        </v-col>
        <v-col cols="3" class="row no-gutters justify-center">
          <span class="text-caption">Без. нал.</span>
        </v-col>
        <v-col cols="2" class="row no-gutters justify-center">
          <span class="text-caption">Всего</span>
        </v-col>
        <v-col cols="2" class="row no-gutters justify-center">
          <span class="text-caption">Кафе</span>
        </v-col>
        <v-col cols="2" class="row no-gutters justify-center">
          <span class="text-caption">Доставка</span>
        </v-col>
      </v-row>
      <v-card-text :class="$vuetify.breakpoint.xs ? 'pt-4' : ''" class="row no-gutters pb-1 px-2" v-for="order in item.orders" :key="order.number">
        <v-col cols="4" class="row no-gutters hidden-sm-and-up grey lighten-4 mt-1 py-3">
          <span class="font-weight-bold pl-4">Номер:</span>
        </v-col>
        <v-col cols="8" class="row no-gutters col col-lg-3 col-md-3 col-sm-3 mt-1 py-3 grey lighten-4 justify-end justify-lg-center justify-md-center justify-sm-center">
          <span class="font-weight-bold mr-2">№</span>
          <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="font-weight-bold">{{ order.number }}</span>
        </v-col>
        <v-col cols="4" class="row no-gutters hidden-sm-and-up grey lighten-4 mt-1 py-3">
          <span class="font-weight-bold pl-4">Без. нал.:</span>
        </v-col>
        <v-col cols="8" v-if="order.paybox_id > 0" class="row no-gutters col col-lg-3 col-md-3 col-sm-3 mt-1 py-3 grey lighten-4 justify-end justify-lg-center justify-md-center justify-sm-center">
          <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="font-weight-bold">{{ order.paybox_id }}</span>
        </v-col>
        <v-col cols="8" v-else class="row no-gutters col col-lg-3 col-md-3 col-sm-3 mt-1 py-3 grey lighten-4 justify-end justify-lg-center justify-md-center justify-sm-center">
          <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="font-weight-bold">-</span>
        </v-col>
        <v-col cols="4" class="row no-gutters hidden-sm-and-up grey lighten-4 mt-1 py-3">
          <span class="font-weight-bold pl-4">Всего:</span>
        </v-col>
        <v-col cols="8" class="row no-gutters col col-lg-2 col-md-2 col-sm-2 mt-1 py-3 grey lighten-4 justify-end justify-lg-center justify-md-center justify-sm-center">
          <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="font-weight-bold">{{ ((order.total_price - order.discount) / 100).toFixed(1) }}</span>
        </v-col>
        <v-col cols="4" class="row no-gutters hidden-sm-and-up grey lighten-4 mt-1 py-3">
          <span class="font-weight-bold pl-4">Кафе:</span>
        </v-col>
        <v-col cols="8" class="row no-gutters col col-lg-2 col-md-2 col-sm-2 mt-1 py-3 grey lighten-4 justify-end justify-lg-center justify-md-center justify-sm-center">
          <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="font-weight-bold">{{ ((order.store_price - order.discount) / 100.0).toFixed(1) }}</span>
        </v-col>
        <v-col cols="4" class="row no-gutters hidden-sm-and-up grey lighten-4 mt-1 py-3">
          <span class="font-weight-bold pl-4">Доставка:</span>
        </v-col>
        <v-col cols="8" class="row no-gutters col col-lg-2 col-md-2 col-sm-2 mt-1 py-3 grey lighten-4 justify-end justify-lg-center justify-md-center justify-sm-center">
          <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="font-weight-bold">{{ (order.delivery_price / 100.0).toFixed(1) }}</span>
        </v-col>
      </v-card-text>
      <v-card-text class="row no-gutters mt-4 pb-1 px-2">
        <v-col cols="12" class="row no-gutters col col-lg-3 col-md-3 col-sm-3 mt-1 py-3 grey lighten-4 justify-center">
          <span class="font-weight-bold">Итого</span>
        </v-col>
        <v-col cols="3" class="row no-gutters mt-1 py-3 grey lighten-4 justify-center hidden-xs-only">
          <span class="font-weight-bold"></span>
        </v-col>
        <v-col cols="4" class="row no-gutters hidden-sm-and-up grey lighten-4 mt-1 py-3">
          <span class="font-weight-bold pl-4">Всего:</span>
        </v-col>
        <v-col cols="8" class="row no-gutters col col-lg-2 col-md-2 col-sm-2 mt-1 py-3 grey lighten-4 justify-end justify-lg-center justify-md-center justify-sm-center">
          <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="font-weight-bold">{{ (sumTotalPrice / 100).toFixed(1) }}</span>
        </v-col>
        <v-col cols="4" class="row no-gutters hidden-sm-and-up grey lighten-4 mt-1 py-3">
          <span class="font-weight-bold pl-4">Кафе:</span>
        </v-col>
        <v-col cols="8" class="row no-gutters col col-lg-2 col-md-2 col-sm-2 mt-1 py-3 grey lighten-4 justify-end justify-lg-center justify-md-center justify-sm-center">
          <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="font-weight-bold">{{ (sumStorePrice / 100).toFixed(1) }}</span>
        </v-col>
        <v-col cols="4" class="row no-gutters hidden-sm-and-up grey lighten-4 mt-1 py-3">
          <span class="font-weight-bold pl-4">Доставка:</span>
        </v-col>
        <v-col cols="8" class="row no-gutters col col-lg-2 col-md-2 col-sm-2 mt-1 py-3 grey lighten-4 justify-end justify-lg-center justify-md-center justify-sm-center">
          <span :class="$vuetify.breakpoint.xs ? 'pr-2' : ''" class="font-weight-bold">{{ (sumDeliveryPrice / 100).toFixed(1) }}</span>
        </v-col>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import format from "date-fns/format";

export default {
  props: {
    driver: Object
  },
  data() {
    return {
      item: {
        orders: {}
      },
      dialog: false
    };
  },
  updated() {
    this.item.id = this.driver.id
    this.item.name = this.driver.name
    this.item.phone = this.driver.phone
    this.item.image = this.driver.image
    this.item.orders = this.driver.orders
  },
  computed: {
    sumTotalPrice() {
      let total = 0
      if (this.item && this.item.orders) {
        for (let i = 0; i < this.item.orders.length; i++) {
          total = total + this.item.orders[i].total_price - this.item.orders[i].discount
        }
      }
      return total
      },
    sumStorePrice() {
      let total = 0
      if (this.item && this.item.orders) {
        for (let i = 0; i < this.item.orders.length; i++) {
          total = total + this.item.orders[i].store_price - this.item.orders[i].discount
        }
      }
      return total
    },
    sumDeliveryPrice() {
      let total = 0
      if (this.item && this.item.orders) {
        for (let i = 0; i < this.item.orders.length; i++) {
          total = total + this.item.orders[i].delivery_price
        }
      }
      return total
    },
    // sumDriverCommission() {
    //   let total = 0;
    //   if (this.item && this.item.orders) {
    //     for (let i = 0; i < this.item.orders.length; i++) {
    //       total = total + this.item.orders[i].driver_commission;
    //     }
    //   }
    //   return total;
    // },
    // sumDriverPrice() {
    //   let total = 0;
    //   if (this.item && this.item.orders) {
    //     for (let i = 0; i < this.item.orders.length; i++) {
    //       total = total + this.item.orders[i].driver_price;
    //     }
    //   }
    //   return total;
    // },
  },
  filters: {
    dateFormat(d) {
      if (!d) {
        return ""
      }
      return format(new Date(d), "yyyy-MM-dd")
    },
    normalizePhone: (p) => {
      if (!p) {
        return ""
      }
      let result = "";
      let phones = p.split(",")

      phones.forEach(phone => {
        phone = phone.replaceAll("+", "").replaceAll(" ", "")
        let temp = "+"
        for (let i = 0; i < phone.length; i++) {
          if (i % 3 === 0) {
            temp = temp + " "
          }
          temp = temp + phone[i]
        }
        result = result + temp + ","
      })

      return result.substr(0, result.length - 1)
    },
    timeFormat(d) {
      if (!d) {
        return ""
      }
      return format(new Date(d), "yyyy-MM-dd HH:mm")
    }
  }
}
</script>
