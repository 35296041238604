<template>
    <v-card class="my-5 pb-2">
      <v-toolbar color="green darken-2" dark>
        <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-calendar</v-icon>
            </v-btn>
          </template>
          <v-date-picker v-model="date" scrollable @input="modal = false; $refs.dialog.save(date); fetch();">
          </v-date-picker>
        </v-dialog>
        <v-toolbar-title>{{ date }}</v-toolbar-title>
      </v-toolbar>
      <v-row dense class="mt-4 mx-2 hidden-xs-only">
        <v-col cols="2" class="row no-gutters justify-center">
          <span class="text-caption">ФИО</span>
        </v-col>
        <v-col cols="2" class="row no-gutters justify-center">
          <span class="text-caption">Телефон</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="text-caption">Начал</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="text-caption">Завершил</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="text-caption">Длительность</span>
        </v-col>
        <v-col cols="12" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-space-around">
          <span class="text-caption mr-5">Всего</span>
          <span class="text-caption">Нал.</span>
          <span class="text-caption">Без нал.</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="text-caption">Нал. сумма</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="text-caption text-no-wrap">Без нал. сумма</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="text-caption">Детали</span>
        </v-col>
      </v-row>
      <v-row :class="$vuetify.breakpoint.xs ? 'pa-2' : 'py-1'" no-gutters v-for="driver in list" :key="driver.id" class="grey lighten-4 mx-1 mb-2">
        <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
          <span class="caption">Курьер:</span>
        </v-col>
        <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-2 col-md-2 col-sm-2 d-flex justify-end justify-lg-center justify-md-center justify-sm-center align-center">
          <span class="font-weight-bold text-body-2">{{ driver.name }}</span>
        </v-col>
        <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
          <span class="caption">Тел.:</span>
        </v-col>
        <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-2 col-md-2 col-sm-2 d-flex justify-end justify-lg-center justify-md-center justify-sm-center align-center">
          <span class="font-weight-bold text-body-2">{{ driver.phone | normalizePhone }}</span>
        </v-col>
        <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
          <span class="caption">Начал:</span>
        </v-col>
        <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-end justify-lg-center justify-md-center justify-sm-center align-center">
          <span class="font-weight-bold text-body-2">{{ driver.created_at | timeFormat }}</span>
        </v-col>
        <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
          <span class="caption">Завершил:</span>
        </v-col>
        <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-end justify-lg-center justify-md-center justify-sm-center align-center">
          <span class="font-weight-bold text-body-2">{{ driver.ended_at | timeFormat }}</span>
        </v-col>
        <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
          <span class="caption">Длительность:</span>
        </v-col>
        <v-col cols="8" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-end justify-lg-center justify-md-center justify-sm-center align-center">
          <span class="font-weight-bold text-body-2">{{ driver.duration }}</span>
        </v-col>
        <v-col cols="12" class="col col-lg-2 col-md-2 col-sm-2 d-flex row no-gutters justify-space-around align-center">
          <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
            <span class="caption">Всего:</span>
          </v-col>
          <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-4 col-md-4 col-sm-4 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center">
            <span class="font-weight-bold text-body-2">{{ driver.orders_count }}</span>
          </v-col>
          <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
            <span class="caption">Нал.:</span>
          </v-col>
          <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-4 col-md-4 col-sm-4 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center">
            <span class="font-weight-bold text-body-2">{{ driver.cash_delivery_count }}</span>
          </v-col>
          <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
            <span class="caption">Без нал.:</span>
          </v-col>
          <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-4 col-md-4 col-sm-4 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center">
            <span class="font-weight-bold text-body-2">{{ driver.paybox_store_count }}</span>
          </v-col>
        </v-col>
        <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
          <span class="caption">Нал. сумма:</span>
        </v-col>
        <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-end justify-lg-center justify-md-center justify-sm-center align-center">
          <span class="font-weight-bold text-body-2">{{ driver.cash_delivery_price > 0 ? (driver.cash_delivery_price / 100.0).toFixed(2) : '' }}</span>
        </v-col>
        <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
          <span class="caption">Безнал. сумма:</span>
        </v-col>
        <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
          <span class="font-weight-bold text-body-2">{{ driver.paybox_store_price > 0 ? (driver.paybox_store_price / 100.0).toFixed(2) : '' }}</span>
        </v-col>
        <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1 d-flex align-center' : ''" class="row no-gutters hidden-sm-and-up">
          <span class="caption">Детально:</span>
        </v-col>
        <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-end justify-lg-center justify-md-center justify-sm-center align-center">
          <info :driver="driver"/>
        </v-col>
      </v-row>
      <v-row :class="$vuetify.breakpoint.xs ? 'pa-2' : 'py-3'" no-gutters class="grey lighten-4 mx-1">
        <v-col cols="12" class="col col-lg-2 col-md-2 col-sm-2 d-flex justify-end justify-lg-center justify-md-center justify-sm-center align-center">
          <span class="font-weight-bold text-body-2">Итого</span>
        </v-col>
        <v-col cols="2" class="hidden-xs-only">
          <span class="font-weight-bold text-body-2"></span>
        </v-col>
        <v-col cols="1" class="hidden-xs-only">
          <span class="font-weight-bold text-body-2"></span>
        </v-col>
        <v-col cols="1" class="hidden-xs-only">
          <span class="font-weight-bold text-body-2"></span>
        </v-col>
        <v-col cols="1" class="hidden-xs-only">
          <span class="font-weight-bold text-body-2"></span>
        </v-col>
        <v-col cols="12" class="col col-lg-2 col-md-2 col-sm-2 d-flex row no-gutters justify-space-around align-center">
          <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
            <span class="caption">Всего:</span>
          </v-col>
          <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-4 col-md-4 col-sm-4 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center">
            <span class="font-weight-bold text-body-2">{{ sumOrdersCount }}</span>
          </v-col>
          <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
            <span class="caption">Нал.:</span>
          </v-col>
          <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-4 col-md-4 col-sm-4 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center">
            <span class="font-weight-bold text-body-2">{{ sumCashOrdersCount }}</span>
          </v-col>
          <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
            <span class="caption">Без нал.:</span>
          </v-col>
          <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-4 col-md-4 col-sm-4 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center">
            <span class="font-weight-bold text-body-2">{{ sumCardOrdersCount }}</span>
          </v-col>

        </v-col>
        <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
          <span class="caption">Нал. сумма:</span>
        </v-col>
        <v-col cols="8" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-end justify-lg-center justify-md-center justify-sm-center align-center">
          <span class="font-weight-bold text-body-2">{{ (sumCashOrdersPrice / 100).toFixed(1) }}</span>
        </v-col>
        <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
          <span class="caption">Без нал. сумма:</span>
        </v-col>
        <v-col cols="8" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
          <span class="font-weight-bold text-body-2">{{ (sumCardOrdersPrice / 100).toFixed(1) }}</span>
        </v-col>
        <v-col cols="1" class="d-flex justify-center align-center">
          <v-icon class="hidden-xs-only">mdi-information</v-icon>
        </v-col>
      </v-row>
    </v-card>
</template>

<script>

import axios from "@/services/axios";
import format from 'date-fns/format';
import info from './details.vue';

export default {
  components: { info },
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    modal: false,
    dialog: false,
    list: [],
  }),

  created() {
    this.fetch();
  },

  computed: {
    sumOrdersCount() {
      let total = 0;
      for (let i = 0; i < this.list.length; i++) {
        total = total + this.list[i].orders_count;
      }
      return total;
    },
    sumCashOrdersCount() {
      let total = 0;
      for (let i = 0; i < this.list.length; i++) {
        total = total + this.list[i].cash_delivery_count;
      }
      return total;
    },
    sumCardOrdersCount() {
      let total = 0;
      for (let i = 0; i < this.list.length; i++) {
        total = total + this.list[i].paybox_store_count;
      }
      return total;
    },
    sumCashOrdersPrice() {
      let total = 0;
      for (let i = 0; i < this.list.length; i++) {
        total = total + this.list[i].cash_delivery_price;
      }
      return total;
    },
    sumCardOrdersPrice() {
      let total = 0;
      for (let i = 0; i < this.list.length; i++) {
        total = total + this.list[i].paybox_store_price;
      }
      return total;
    },
  },

  methods: {
    fetch() {
      axios.send({
        url: "/stat/drivers?date=" + this.date,
        method: "GET",
      }).then((resp) => {
        this.list = resp.data;
      });
    }
  },
  filters: {
    normalizePhone: (p) => {
      if (!p) {
        return ""
      }
      let result = "";
      let phones = p.split(",");

      phones.forEach(phone => {
        phone = phone.replaceAll("+", "").replaceAll(" ", "")
        let temp = "+"
        for (let i = 0; i < phone.length; i++) {
          if (i % 3 === 0) {
            temp = temp + " "
          }
          temp = temp + phone[i]
        }
        result = result + temp + ","
      })

      return result.substr(0, result.length - 1)
    },
    timeFormat(d) {
      if (!d) {
        return ""
      }
      return format(new Date(d), "HH:mm")
    },
  },
}
</script>
